import { useState } from 'react';
import { socialMediaTransparent } from '@/components/Footer/utils';
import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { Typography } from '@/components/Typography';
import { trackEvent } from '@/helpers/analytics';
import {
  ConnectWithAkuityIcon,
  GotATechnicalQuestionIcon,
  LearnHowToUseIcon,
  ReadTheAkuityDocsIcon,
  SlackCommunityIcon,
  StillHaveAQuestionIcon,
  WantToGetStartedIcon,
  WantToStartFreeTrialIcon
} from '@/static/assets/icons';
import { seoConfiguration } from '@/utils/seo-config';

import './connect-with-akuity.scss';

const { title: titleSeo, image, description } = seoConfiguration.contact;

const ContactPage = () => {
  return (
    <Layout {...content.seo}>
      <PageHeader
        textPrimary={{
          text: content.header,
          color: 'akuity-purple'
        }}
        textDescription={{
          text: content.subheader
        }}
      />

      <div className="seperate-section-wrapper no-global-padding">
        <div className="background-bubbles" />
        <div className="background-bubbles" />
        <div className="seperate-section padding-global">
          <HelperSection
            id="get-started"
            header={content.section.getStarted.header}
            items={[
              ...content.section.getStarted.items,
              ...content.section.rest.items
            ]}
          />
        </div>
      </div>
    </Layout>
  );
};

const HelperSection = ({
  header,
  className,
  items,
  id
}: {
  id: string;
  header: string;
  className?: string;
  items: Array<{
    icon: string;
    text: string;
    link?: string;
    isExternalLink?: boolean;
    isSocialMedia?: boolean;
    onClick?: () => void;
  }>;
}) => {
  const [showSocialMediaIcon, setShowSocialMediaIcon] = useState(false);

  return (
    <div className={className} id={id}>
      <div className="helper-cards-wrapper row">
        {items.map((item, idx) => (
          <div
            key={idx}
            className="helper-card-wrapper col-sm-6 col-md-6 col-lg-4"
          >
            <a
              href={item.link}
              // @ts-ignore
              target={item.isExternalLink ? '_blank' : ''}
              rel="noreferrer"
              onClick={() => {
                setShowSocialMediaIcon(true);
                item.onClick && item.onClick();
              }}
            >
              <div className="helper-card">
                <item.icon />
                <Typography
                  component="p"
                  typographyKind="text-primary-medium-regular"
                >
                  {item.text}
                </Typography>
              </div>
            </a>
            {item.isSocialMedia && showSocialMediaIcon && (
              <div className="social-media-icons">
                {socialMediaTransparent.map((icon) => (
                  <a
                    key={icon.id}
                    target="_blank"
                    rel="noreferrer"
                    href={icon.url}
                  >
                    <icon.icon />
                  </a>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const content = {
  seo: {
    title: titleSeo,
    description,
    image
  },
  header: 'Connect with Akuity',
  subheader: `We’re here to help you! Check out these resources and ways to connect with us`,
  links: [
    {
      text: 'Get started',
      url: '/connect-with-akuity/#get-started'
    },
    {
      text: 'Everything else',
      url: '/connect-with-akuity/#everything-else'
    }
  ],
  section: {
    community: {
      header: 'Join the community',
      items: [
        {
          icon: SlackCommunityIcon,
          text: 'Join the Akuity Community Slack',
          link: 'https://akuity-community.slack.com',
          isExternalLink: true
        },
        {
          icon: LearnHowToUseIcon,
          text: 'Learn how to use the Akuity Platform',
          link: 'https://docs.akuity.io/#',
          isExternalLink: true
        },
        {
          icon: ReadTheAkuityDocsIcon,
          text: 'Read the Akuity Docs',
          link: 'https://docs.akuity.io',
          isExternalLink: true
        }
      ]
    },
    getStarted: {
      header: 'Get started',
      items: [
        {
          icon: ReadTheAkuityDocsIcon,
          text: 'Read the Akuity Docs',
          link: 'https://docs.akuity.io',
          isExternalLink: true
        },
        {
          icon: GotATechnicalQuestionIcon,
          text: 'Got a technical question, or want a demo?',
          link: '/get-demo',
          isExternalLink: false
        },
        {
          icon: WantToStartFreeTrialIcon,
          text: 'Want to start a free trial of the Akuity Platform?',
          link: '/signup',
          isExternalLink: true,
          onClick: () => trackEvent('akuity_io_to_cloud')
        },
        {
          icon: WantToGetStartedIcon,
          text: 'Want to get started with Akuity Enterprise?',
          link: '/talk-to-sales'
        }
      ]
    },
    rest: {
      header: 'Everything else',
      items: [
        {
          icon: ConnectWithAkuityIcon,
          text: 'Connect with Akuity on social media',
          isSocialMedia: true
        },
        {
          icon: StillHaveAQuestionIcon,
          text: 'Still have questions?',
          link: '/contact'
        }
      ]
    }
  }
};

export default ContactPage;
